import {Directive, OnInit} from '@angular/core';
import {DataBindingDirective, GridComponent, GridDataResult} from '@progress/kendo-angular-grid';
import {catchError, finalize} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {DeviceService} from '../service/device.service';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material';
import {HttpErrorResponse} from '@angular/common/http';

@Directive({
  selector: '[appDeviceBinding]'
})
export class DeviceTableDirective extends DataBindingDirective implements OnInit {

  constructor(
    private dataProvider: DeviceService,
    public grid: GridComponent,
    public translateService: TranslateService,
    public snackBar: MatSnackBar
  ) {
    super(grid);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.rebind();
  }

  public deleteItem(itemId: number) {
    this.dataProvider.deleteOne(itemId)
      .subscribe(
        data => {
          this.translateService.get('Device successfully deleted').subscribe((res) => this.snackBar.open(res));
          this.rebind();
        },
        function(error: HttpErrorResponse) {
          console.log(error);
        }
      );
  }

  public rebind(): void {
    this.grid.loading = true;
    /**
     * TODO: remake default filters to right way.
     * @see https://www.telerik.com/kendo-angular-ui/components/grid/filtering/
     */
    if (!this.state.filter) {
      this.state.filter = {
        logic: 'and',
        filters: []
      };
    }

    this.state.filter.filters.push(
      {
        field: 'equipment',
        operator: 'isnull'
      }
    );

    this.dataProvider
      .find(this.state)
      .pipe(
        catchError(() => of([])),
      )
      .subscribe((response) => {
        this.grid.loading = false;  // hide the loading indicator
        this.grid.data = (<GridDataResult>{
          data: response['data'],
          total: parseInt(response['total'], 10)
        });
        this.notifyDataChange();
      });
  }


}
