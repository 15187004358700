﻿import {Component, OnDestroy, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SessionService} from './_services';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {MatSidenav} from '@angular/material';
import {UserService} from './_services/user.service';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'app',
  templateUrl: 'app.component.html'
})

export class AppComponent implements OnDestroy {
  public user: any;
  public isOpen: Boolean;
  @ViewChild(MatSidenav) sidenav: MatSidenav;
  private subscription: Subscription;

  constructor(
    private translateService: TranslateService,
    public sessionService: SessionService,
    private router: Router,
    public userService: UserService,
    public  deviceDetector: DeviceDetectorService
  ) {
    translateService.addLangs(['en', 'ru']);
    translateService.setDefaultLang('en');

    const browserLang = translateService.getBrowserLang();
    translateService.use(browserLang.match(/en|ru/) ? browserLang : 'en');
  }

  logout() {
    this.sessionService.clearCurrentUser();
    this.router.navigate(['/login']);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  toggle() {
    this.sidenav.toggle();
  }
}
