import {Component, ViewChild} from '@angular/core';
import {SortDescriptor, State} from '@progress/kendo-data-query';
import {EqupmentTableDirective} from './equipment-table.directive';
import {DeviceDetectorService} from 'ngx-device-detector';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {UserService} from '../../_services/user.service';

@Component({
  selector: 'app-equipment-table',
  templateUrl: './equipment-table.component.html',
  styleUrls: ['./equipment-table.component.scss']
})

export class EquipmentTableComponent {

  public state: State = {};
  public sort: SortDescriptor[] = [{
    field: 'status.code',
    dir: 'desc'
  }];
  public isMobile: Boolean = false;
  public faTrash = faTrash;


  @ViewChild(EqupmentTableDirective) dataBinding: EqupmentTableDirective;

  constructor(
    deviceDetector: DeviceDetectorService,
    public userService: UserService
  ) {
    this.isMobile = deviceDetector.isMobile();
  }

  public refresh(showSpinner: boolean = true) {
    this.dataBinding.rebind(showSpinner);
  }

  public deleteItem(itemId: number) {
    this.dataBinding.deleteItem(itemId);
  }

}



