﻿import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {SessionService} from '../../_services/session.service';
import {Equipment} from '../model/equipment';
import {Observable, of} from 'rxjs';
import {stringify} from 'querystring';
import {SearchResult} from '../model/search-result';

@Injectable()
export class EquipmentService {
  constructor(private http: HttpClient, private session: SessionService) {
  }


  create(factoryNumber: string, address: string, comment: string):  Observable<HttpResponse<any>>
  {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http
      .post<any>(
        `${environment.apiUrl}/api/equipment`,
        JSON.stringify({factoryNumber, address, comment}),
        {headers: headers, observe: 'response'}
      );
  }

  update(id: string, factoryNumber: string, address: string, comment: string):  Observable<HttpResponse<any>>
  {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/equipment/${id}`,
        JSON.stringify({factoryNumber, address, comment}),
        {headers: headers, observe: 'response'}
      );
  }

  find(criteria: object) {

    // TODO: replace any to Equipment
    return this.http.get<SearchResult>(
      `${environment.apiUrl}/api/equipment`,
      {params: {criteria: JSON.stringify(criteria)}}
    );
  }

  getOneById(id) {
    return this.http.get<Equipment>(
      `${environment.apiUrl}/api/equipment/${id}`
    );
  }

  deleteOne(entityId:  number) {
    return this.http.delete<any>(
      `${environment.apiUrl}/api/equipment/${entityId}`
    );
  }
}

