﻿import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {SessionService} from '../../_services/session.service';
import {Signal} from '../model/signal';

@Injectable()
export class SignalService {
  constructor(private http: HttpClient, private session: SessionService) {
  }

  find(criteria: object) {
    return this.http.get<Signal[]>(
      `${environment.apiUrl}/api/device_signals`,
      {params: {criteria: JSON.stringify(criteria)}}
    );
  }

}

