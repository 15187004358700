import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {finalize, first} from 'rxjs/operators';
import {EquipmentService} from '../service/equipment.service';
import {HttpErrorResponse} from '@angular/common/http';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-create-equipment-dialog',
  templateUrl: './create-equipment-dialog.component.html',
  styleUrls: ['./create-equipment-dialog.component.scss']
})
export class CreateEquipmentDialogComponent implements OnInit {

  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private equipmentService: EquipmentService,
    public dialogRef: MatDialogRef<CreateEquipmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      factoryNumber: ['', Validators.required],
      address: ['', Validators.required],
      comment: [''],
    });
  }

  get f() {
    return this.form.controls;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    if (this.loading) {
      return;
    }

    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.equipmentService
      .create(
        this.f.factoryNumber.value,
        this.f.address.value,
        this.f.comment.value
      )
      .pipe(
        first(),
        finalize(() => this.loading = false)
      )
      .subscribe(
        data => this.dialogRef.close(true),
        this.handleError.bind(this),
      );
  }

  private handleSuccess() {
    this.dialogRef.close(true);
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error.violations) {
      for (let violation of error.error.violations) {
        this.form.controls[violation.propertyPath].setErrors({'fromBackend': violation.message});
      }
    }
  }
}
