﻿import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

import {environment} from '../../../environments/environment';
import {SessionService} from '../../_services/session.service';
import {Device} from '../model/device';
import {Observable} from 'rxjs';

@Injectable()
export class DeviceService {
  constructor(private http: HttpClient, private session: SessionService) {
  }

  protected updateDevice(device: Device): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http
      .put<any>(
        `${environment.apiUrl}/api/devices/${device.id}`,
        JSON.stringify(device),
        {headers: headers, observe: 'response'}
      );
  }

  bindToEquipment(device: Device, equipmentId: string): Observable<HttpResponse<any>> {
    device.equipment = equipmentId;
    return this.updateDevice(device);
  }

  unbindToEquipment(device: Device): Observable<HttpResponse<any>> {
    device.equipment = null;
    return this.updateDevice(device);
  }

  findWithoutEquipmet(pageIndex = 0, pageSize = 10) {
    return this.http.get<Device[]>(
      `${environment.apiUrl}/api/devices?equipment[exists]=false`,
      {
        params: {
          page: (pageIndex + 1).toString(),
          itemsPerPage: pageSize.toString()
        }
      }
    );
  }

  find(criteria: Object = {}) {
    // TODO: fix any
    return this.http.get<any>(
      `${environment.apiUrl}/api/devices`,
      {params: {criteria: JSON.stringify(criteria)}}
    );
  }

  deleteOne(entityId:  number) {
    return this.http.delete<any>(
      `${environment.apiUrl}/api/devices/${entityId}`
    );
  }

  getDevice(id) {
    return this.http.get<Device>(
      `${environment.apiUrl}/api/devices/${id}`
    );
  }

  logout() {
    // remove user from local storage to log user out
    this.session.clearCurrentUser();
  }
}

