import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as _moment from 'moment';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'DD.MM.YYYY',
    monthYearA11yLabel: 'MM.YYYY',
  },
};

@Component({
  selector: 'app-signal-table-filter-form',
  templateUrl: './filter-form.component.html',
  styleUrls: ['./filter-form.component.scss'],
  providers: [

    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    {provide: MAT_DATE_LOCALE, useValue: 'ru'},
  ],
})
export class FilterFormComponent implements OnInit {

  @Output() filterStateChanged = new EventEmitter<any>();

  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      dateFrom: ['', Validators.required],
      dateTo: ['', Validators.required],
    });
  }

  isPeriodValid(dateFrom, dateTo) {
    return dateTo.isAfter(dateFrom);
  }

  applyFilter($event: any) {
    const formControlName = $event.targetElement.getAttribute('formControlName');

    const bothDatesAreSettedButPeriodIsIncorrect =
      Boolean(this.form.controls['dateFrom'].value)
      && Boolean(this.form.controls['dateTo'].value)
      && !this.isPeriodValid(this.form.controls['dateFrom'].value, this.form.controls['dateTo'].value);

    if (bothDatesAreSettedButPeriodIsIncorrect) {
        this.form.controls[formControlName].setErrors({'message': 'The entered period is not correct'});
    }

    const filterValues = {
      dateFrom: this.form.controls['dateFrom'].value !== '' ? this.form.controls['dateFrom'].value : null,
      dateTo:  this.form.controls['dateTo'].value !== '' ? this.form.controls['dateTo'].value : null,
    };

    this.filterStateChanged.emit(filterValues);
  }

  reset() {
    // TODO: find right way
    this.form.controls['dateFrom'].reset();
    this.form.controls['dateTo'].reset();
    this.filterStateChanged.emit({});
  }

  get f() {
    return this.form.controls;
  }
}
