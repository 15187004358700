import {Directive, Input, OnInit} from '@angular/core';
import {DataBindingDirective, GridComponent, GridDataResult} from '@progress/kendo-angular-grid';
import {catchError, finalize} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {Signal} from '../model/signal';
import {SignalService} from '../service/signal.service';
import {Equipment} from '../../equipment/model/equipment';
import {Device} from '../../device/model/device';
import {CompositeFilterDescriptor, State} from '@progress/kendo-data-query';

@Directive({
  selector: '[appSignalTableDirective]'
})
export class SignalTableDirective extends DataBindingDirective implements OnInit {

  @Input() device: Device = null;
  @Input() equipment: Equipment = null;

  private loadDataSubscription: Observable<Signal[]>;

  filter: CompositeFilterDescriptor

  constructor(private signalService: SignalService, public grid: GridComponent) {
    super(grid);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.rebind();
  }

  public getCurrentState(): State {
    return JSON.parse(JSON.stringify(this.state))
  }

  public rebind(): void {
    this.grid.loading = true;

    /**
     * TODO: remake default filters to right way.
     * @see https://www.telerik.com/kendo-angular-ui/components/grid/filtering/
     */
    if (!this.state.filter) {
      this.state.filter = {
        logic: 'and',
        filters: []
      };
    }

    if (this.equipment) {
      this.state.filter.filters = [
        {
          field: 'equipment',
          operator: 'eq',
          value: this.equipment.id

        }
      ];
    }
    if (this.device) {
      this.state.filter.filters = [
        {
          field: 'device',
          operator: 'eq',
          value: this.device.id
        }
      ];
    }

    this.signalService
      .find(this.state)
      .pipe(
        catchError(() => of([])),
      )
      .subscribe((response) => {
        this.grid.loading = false;  // hide the loading indicator
        this.grid.data = (<GridDataResult>{
          data: response['data'],
          total: parseInt(response['total'], 10)
        });
        this.notifyDataChange();
      });
  }
}
