﻿import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {SessionService} from '../_services';
import {User} from '../_models';
import {UserService} from '../_services/user.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private sessionService: SessionService,
    public userService: UserService
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (route.routeConfig.path === 'device') {
      return this.userService.isGrantedRoleToCurrentUser('ROLE_ADMIN') || this.userService.isGrantedRoleToCurrentUser('ROLE_INSTALLER');
    }
    if (this.sessionService.getCurrentUser()) {
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
    return false;
  }
}
