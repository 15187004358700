﻿import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {User} from '../_models';

const currentUserKey = 'currentUser';

@Injectable()
export class SessionService {


  private subject = new Subject<any>();
  private user = null;

  private loginSubject: BehaviorSubject<boolean>;

  public isLoggedIn$: Observable<boolean>;

  constructor() {
    this.loginSubject = new BehaviorSubject<boolean>(Boolean(localStorage.getItem(currentUserKey)));
    this.isLoggedIn$ = this.loginSubject.asObservable();
  }


  setCurrentUser(user) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.loginSubject.next(Boolean(user));
  }

  clearCurrentUser() {
    localStorage.removeItem('currentUser');
    this.loginSubject.next(false);
  }

  getCurrentUser(): User | null {

    return localStorage.getItem('currentUser')
      ? JSON.parse(localStorage.getItem('currentUser'))
      : null;
  }
}
