import {Directive, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataBindingDirective, GridComponent, GridDataResult} from '@progress/kendo-angular-grid';
import {EquipmentService} from '../service/equipment.service';
import {catchError, first} from 'rxjs/operators';
import {of, Subscription, timer} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Directive({
  selector: '[appEquipmentsBinding]'
})
export class EqupmentTableDirective extends DataBindingDirective implements OnInit, OnDestroy {

  private timerSubscription: Subscription;

  constructor(
    private dataProvider: EquipmentService,
    public grid: GridComponent,
    public translateService: TranslateService,
    public snackBar: MatSnackBar
  ) {
    super(grid);
  }


  public ngOnInit(): void {
    super.ngOnInit();
    this.rebind();
    this.timerSubscription = timer(5000, 5000).subscribe(() => {
      this.rebind(false);
    });

  }

  public rebind(showSpinner: boolean = true) {
    this.grid.loading = showSpinner;
    const observable = this.dataProvider
      .find(this.state)
      .pipe(
        catchError(() => of([])),
      );

    observable
      .subscribe((response) => {
        this.grid.loading = false;  // hide the loading indicator
        this.grid.data = (<GridDataResult>{
          data: response['data'],
          total: parseInt(response['total'], 10)
        });
        this.notifyDataChange();
      });

    return observable;
  }

  public ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  public deleteItem(itemId: number) {
    this.dataProvider.deleteOne(itemId)
      .subscribe(
        data => {
          this.translateService.get('Equipment successfully deleted').subscribe((res) => this.snackBar.open(res));
          this.rebind();
        },
        function(error: HttpErrorResponse) {
          console.log(error);
        }
      );
  }
}
