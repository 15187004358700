import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator} from '@angular/material';
import {CreateEquipmentDialogComponent} from './create-equipment-dialog/create-equipment-dialog.component';
import {EquipmentTableComponent} from './equipment-table/equipment-table.component';
import {first, tap} from 'rxjs/operators';
import {SessionService} from '../_services';
import {UserService} from '../_services/user.service';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.css']
})
export class EquipmentComponent implements OnInit {

  @ViewChild(EquipmentTableComponent) equipmentTableComponent: EquipmentTableComponent;

  constructor(public dialog: MatDialog, public userService: UserService) {

  }


  ngOnInit() {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CreateEquipmentDialogComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.equipmentTableComponent.refresh();
      }
    });
  }


}
