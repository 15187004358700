import {Component, ViewChild} from '@angular/core';
import {SortDescriptor, State} from '@progress/kendo-data-query';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DeviceTableDirective} from './device-table.directive';
import {faTrash} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-device-table',
  templateUrl: './device-table.component.html',
  styleUrls: ['./device-table.component.css']
})

export class DeviceTableComponent {

  public state: State = {};
  public sort: SortDescriptor[] = [{
    field: 'status.value',
    dir: 'desc'
  }];
  public isMobile: Boolean = false;
  public faTrash = faTrash;

  @ViewChild(DeviceTableDirective) dataBinding: DeviceTableDirective;

  constructor(deviceDetector: DeviceDetectorService) {
    this.isMobile = deviceDetector.isMobile();
  }

  public refresh() {
    this.dataBinding.rebind();
  }

  public deleteItem(itemId: number) {

    // console.log(item);
    this.dataBinding.deleteItem(itemId);
  }
}

