﻿import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { LoginComponent } from './login';
import {  AuthGuard } from './_guards';
import {EquipmentComponent} from './equipment/equipment.component';
import {DeviceComponent} from './device/device.component';
import {DeviceDetailComponent} from './device/device-detail/device-detail.component';
import {EquipmentDetailComponent} from './equipment/equipment-detail/equipment-detail.component';

const appRoutes: Routes = [
    { path: '', component: EquipmentComponent, canActivate: [AuthGuard]  },
    { path: 'login', component: LoginComponent },
    { path: 'equipment', component: EquipmentComponent, canActivate: [AuthGuard] },
    { path: 'device', component: DeviceComponent, canActivate: [AuthGuard] },
    { path: 'device/:id', component: DeviceDetailComponent, canActivate: [AuthGuard], data: { animation: 'device' } },
    { path: 'equipment/:id', component: EquipmentDetailComponent, canActivate: [AuthGuard], data: { animation: 'equipment' } },
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);
