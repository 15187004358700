import {Component, Input, ViewChild} from '@angular/core';
import {Device} from '../../device/model/device';
import {Equipment} from '../../equipment/model/equipment';
import {faFileExcel} from '@fortawesome/free-solid-svg-icons';
import {SortDescriptor, State} from '@progress/kendo-data-query';
import {SignalTableDirective} from './signal-table.directive';
import {Signal} from '../model/signal';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';


@Component({
  selector: 'app-signal-table',
  templateUrl: './signal-table.component.html',
  styleUrls: ['./signal-table.component.scss']
})
export class SignalTableComponent {
  @Input() device: Device = null;
  @Input() equipment: Equipment = null;

  @ViewChild(SignalTableDirective) dataBinding: SignalTableDirective;

  public faFileExcel = faFileExcel;
  public state: State = {};
  public sort: SortDescriptor[] = [{
    field: 'receivingDateTime',
    dir: 'desc'
  }];
  private JSON: JSON;


  constructor(private http: HttpClient) {
    this.JSON = JSON;
  }

  public refresh() {
    this.dataBinding.rebind();
  }


  download() {
    let state = this.dataBinding.getCurrentState();

    if (!state.filter) {
      state.filter = {
        logic: 'and',
        filters: []
      };
    }

    if (this.equipment) {
      state.filter.filters = [
        {
          field: 'equipment',
          operator: 'eq',
          value: this.equipment.id

        }
      ];
    }
    if (this.device) {
      state.filter.filters = [
        {
          field: 'device',
          operator: 'eq',
          value: this.device.id
        }
      ];
    }

    delete state.take;
    delete state.skip;

    this.http
      .get(
        `${environment.apiUrl}/api/device_signals/xlsx`,
        {responseType: 'blob', params: {criteria: JSON.stringify(state)}}
      )
      .subscribe((newBlob) => {
        // debugger;
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        // var newBlob = new Blob([data], { type: "application/pdf" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob, 'device_signals.xlsx');
          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const blob = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = blob;
        link.setAttribute('target', '_blank');
        link.download = 'device_signals.xlsx';
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(blob);
        }, 100);
      });
  }
}


