import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {finalize, first, switchMap} from 'rxjs/operators';
import {EquipmentService} from '../service/equipment.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Equipment} from '../model/equipment';
import {DeviceService} from '../../device/service/device.service';
import {Device} from '../../device/model/device';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material';
import {UserService} from '../../_services/user.service';

@Component({
  selector: 'app-equipment-detail',
  templateUrl: './equipment-detail.component.html',
  styleUrls: ['./equipment-detail.component.scss']
})
export class EquipmentDetailComponent implements OnInit {

  form: FormGroup;
  loading = false;
  submitted = false;
  bindSubmitting = false;
  entity$: Observable<Equipment>;
  equipment: Equipment = null;
  device: Device = null;
  private readonly: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private equipmentService: EquipmentService,
    private route: ActivatedRoute,
    private router: Router,
    private deviceService: DeviceService,
    public snackBar: MatSnackBar,
    public translateService: TranslateService,
    public userService: UserService
  ) {

    this.readonly = !userService.isGrantedRoleToCurrentUser('ROLE_ADMIN') && !userService.isGrantedRoleToCurrentUser('ROLE_INSTALLER')
  }

  ngOnInit() {

    this.form = this.formBuilder.group({
      factoryNumber: ['', Validators.required],
      address: ['', Validators.required],
      comment: [''],
      device: [''],
    });

    this.entity$ = this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
            const equipmentId = params.get('id');
            const getEquipmentObservable = this.equipmentService.getOneById(params.get('id'));
            getEquipmentObservable.subscribe(data => {
              this.equipment = data;
              this.form.controls.factoryNumber.setValue(data.factoryNumber);
              this.form.controls.address.setValue(data.address);
              this.form.controls.comment.setValue(data.comment);
            });

            this.deviceService.find({
              skip: 0,
              take: 1,
              filter: {
                filters: [
                  {
                    field: 'equipment',
                    operator: 'eq',
                    value: equipmentId
                  },
                ],
                logic: 'and'
              }
            }).subscribe(
              data => {
                if (data.total > 0 ) {
                  this.device = data.data[0];
                  this.device.equipment = this.equipment;
                  this.form.controls.device.setValue(this.device.code);
                }
              }
            );

            return getEquipmentObservable;
          }
        )
      );
  }

  get isReadonly() {
    return this.readonly;
  }

  get f() {
    return this.form.controls;
  }

  submit() {
    if (this.loading) {
      return;
    }

    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.equipmentService
      .update(
        this.equipment.id,
        this.f.factoryNumber.value,
        this.f.address.value,
        this.f.comment.value
      )
      .pipe(
        first(),
        finalize(() => this.loading = false)
      )
      .subscribe(
        data => {
          this.translateService.get('Data successfully saved').subscribe((res) => this.snackBar.open(res));
        },
        this.handleError.bind(this),
      );
  }

  unbind() {
    if (this.device === null) {
      return;
    }

    this.bindSubmitting = true;
    this.deviceService
      .unbindToEquipment(
        this.device
      )
      .pipe(first())
      .subscribe(
        data => {
          this.device = null;
          this.form.controls.device.setValue('');
          this.bindSubmitting = false;
          this.translateService.get('Device successfully unbound').subscribe((res) => this.snackBar.open(res));
        }
      );
  }

  bind() {
    if (this.device === null) {
      return;
    }

    this.bindSubmitting = true;
    this.deviceService
      .bindToEquipment(
        this.device,
        this.equipment.id
      )
      .pipe(first())
      .subscribe(
        data => {
          this.device.equipment = this.equipment.id;
          this.bindSubmitting = false;
        }
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error.violations) {
      for (let violation of error.error.violations) {
        this.form.controls[violation.propertyPath].setErrors({'fromBackend': violation.message});
      }
    }
  }

  gotoBack(entity: Equipment) {
    let id = entity ? entity.id : null;
    this.router.navigate(['/equipment', {id: id}]);
  }
}
