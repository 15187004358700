﻿  import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {SessionService} from './session.service';

@Injectable()
export class AuthenticationService {
  constructor(private http: HttpClient, private session: SessionService) {
  }


  login(username: string, password: string) {

    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(
      `${environment.apiUrl}/login`,
      JSON.stringify({username: username, password: password}),
      {headers: headers}
    )
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.session.setCurrentUser(user);
        }

        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    this.session.clearCurrentUser();

  }
}
