﻿import {Injectable} from '@angular/core';
import {SessionService} from './session.service';

@Injectable()
export class UserService {
  constructor(private session: SessionService) {
  }

  isGrantedRoleToCurrentUser(role: string): boolean {
    const user = this.session.getCurrentUser();

    if (null == user) {
      return false;
    }
    return user.roles.indexOf(role) !== -1;
  }
}
