﻿import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatButtonModule,
  MatIconModule,
  MatListModule,
  MatSidenavModule,
  MatToolbarModule
} from '@angular/material';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
// used to create fake backend
import {ErrorInterceptor, JwtInterceptor} from './_helpers';
import {AppComponent} from './app.component';
import {routing} from './app.routing';

import {AlertComponent} from './_directives';
import {AuthGuard} from './_guards';
import {AlertService, AuthenticationService, SessionService, UserService} from './_services';
import {HomeComponent} from './home';
import {LoginComponent} from './login';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DemoMaterialModule} from './material-module';
import {LayoutModule} from '@angular/cdk/layout';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {EquipmentComponent} from './equipment/equipment.component';
import {CreateEquipmentDialogComponent} from './equipment/create-equipment-dialog/create-equipment-dialog.component';
import {EquipmentService} from './equipment/service/equipment.service';
import {EquipmentTableComponent} from './equipment/equipment-table/equipment-table.component';
import {DeviceComponent} from './device/device.component';
import {DeviceService} from './device/service/device.service';
import {DeviceTableComponent} from './device/device-table/device-table.component';
import {DeviceDetailComponent} from './device/device-detail/device-detail.component';
import {SignalTableComponent} from './signal/signal-table/signal-table.component';
import {SignalService} from './signal/service/signal.service';
import {EquipmentDetailComponent} from './equipment/equipment-detail/equipment-detail.component';
import {FilterFormComponent as SignalTableFilterFormComponent} from './signal/signal-table/filter-form/filter-form.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {DeviceDetectorModule} from 'ngx-device-detector';

import {GridModule} from '@progress/kendo-angular-grid';
import {EqupmentTableDirective} from './equipment/equipment-table/equipment-table.directive';
import {SignalTableDirective} from './signal/signal-table/signal-table.directive';
import {DeviceTableDirective} from './device/device-table/device-table.directive';
import { MessageService } from '@progress/kendo-angular-l10n';
import {KendoGridTranslateMessageService} from './_services/kendo-grid-translate-message.service';

import { LOCALE_ID } from '@angular/core';

import '@progress/kendo-angular-intl/locales/ru/all';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    routing,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    FontAwesomeModule,
    DeviceDetectorModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    GridModule,
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    HomeComponent,
    LoginComponent,
    EquipmentComponent,
    CreateEquipmentDialogComponent,
    EquipmentTableComponent,
    DeviceComponent,
    DeviceTableComponent,
    DeviceDetailComponent,
    SignalTableComponent,
    EquipmentDetailComponent,
    SignalTableFilterFormComponent,
    EqupmentTableDirective,
    DeviceTableDirective,
    SignalTableDirective
  ],
  entryComponents: [CreateEquipmentDialogComponent],
  providers: [
    AuthGuard,
    AlertService,
    SessionService,
    AuthenticationService,
    UserService,
    EquipmentService,
    DeviceService,
    SignalService,
    {provide: MessageService, useClass: KendoGridTranslateMessageService },
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {verticalPosition: 'top', panelClass: ['custom-snackbar'], duration: 4000}},
    {provide: LOCALE_ID, useValue: 'ru'}
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
